.report-overview-logo {
  display: none;
  margin: 20px 0;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  * {
    font-size: 13px !important;
  }

  .no-print {
    display: none !important;
  }

  .report-overview-logo {
    display: block !important;
  }

  .content-container {
    padding: 20px !important;
    margin: 0 !important;
    display: flex;
  }

  .vertical-container {
    padding: 0 !important;
    margin-left: 20px !important;
  }
  .content-container > div:nth-child(1) {
    display: flex !important;
    flex-direction: column !important;
    flex-grow: 2 !important;
  }
  .content-container > div:nth-last-child(1) {
    margin-right: 20px;
  }
}

@media print {
  .page-break {
    margin-top: 1rem !important;
    display: block !important;
    page-break-before: always !important;
  }
}

@page {
  size: auto;
  margin: 10mm;
}
