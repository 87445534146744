@media print {
  html,
  body {
    page-break-after: avoid;
    page-break-before: avoid;
    page-break-inside: avoid;
  }

  /* Hide elements that are not necessary for the print version */
  .no-print {
    display: none !important;
  }

  /* Ensure content is not cut off */
  .content {
    margin-left: -35px !important;
    margin-right: 45px;
    page-break-inside: avoid;
  }
}
